import React, {Fragment, useState} from 'react';
import './index.scss';
import logo from '../../assets/images/logo.svg'
import mobileHeader from '../../assets/images/img_5.png'

const Header = () => {
    interface HeaderList {
        name: String;
        id: Number;
        isActive: Boolean;
    }

    // const [top] = useState(0)

    const [headerList, setHeaderList] = useState<HeaderList[]>([
        {
            name: '品牌介绍',
            id: 0,
            isActive: true
        },
        {
            name: '精酿系列',
            id: 1,
            isActive: false
        },
        {
            name: '果味沁啤',
            id: 2,
            isActive: false
        },
        {
            name: '联系我们',
            id: 3,
            isActive: false
        }
    ])

    const clickNavs = (item: HeaderList) => {
        setHeaderList(
            headerList.map((i: HeaderList) => {
                if (i.id === item.id) {
                    return {...i, isActive: true}
                } else {
                    return {...i, isActive: false}
                }
            })
        )

        // @ts-ignore
        switch (item.id) {
            case 0:
                console.log(item);
                // @ts-ignore
                document.body.scrollTop = document.documentElement.scrollTop = 0
                // document.getElementById('brandIntroduction').scrollIntoView()
                break;
            case 1:
                // @ts-ignore
                let dom = document.getElementById('CraftBrewing')
                // @ts-ignore
                dom.scrollIntoView()
                console.log(dom)
                break;

            case 2:
                // @ts-ignore
                let dom1 = document.getElementById('QinBeer')
                // @ts-ignore
                dom1.scrollIntoView()
                break;

            case 3:
                // @ts-ignore
                let dom2 = document.getElementById('Contact')


                // @ts-ignore
                dom2.scrollIntoView()
                break;
            default:
                console.log(item)
        }

    }

    return (
        <Fragment>
            <div className={'pcHeader'}>
                <div style={{position: "fixed", top: 0, zIndex: 9999}} className={'w-full'}>
                    <div className={'background flex items-center justify-center h-20'}>
                        <div>
                            <img src={logo} alt="巴兰德LOGO"/>
                        </div>

                        <div className={'flex items-center navList'}>
                            {
                                headerList.map((item, index) => {
                                    return (
                                        <div key={index} onClick={() => {
                                            clickNavs(item)
                                        }}>
                                            <div
                                                className={item.isActive ? 'navs navsActive w-28 text-center cursor-pointer' : 'navs text-center cursor-pointer'}>{item.name}</div>
                                            {item.isActive && <div className={'isActive w-20'}></div>}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>


            <div className={'mobileHeader'}>
                <img src={mobileHeader} alt=""/>


            </div>
        </Fragment>
    );
};

export default Header;
