import React, {useState} from 'react';
import './index.scss'
import img1 from '../../assets/images/img_1.png'
import qinSerise from '../../assets/images/qinSerise.svg'

import peach from "../../assets/images/peach.png";
import coconut from "../../assets/images/coconut.png";
import lemon from "../../assets/images/lemon.png";
import Strawberry from "../../assets/images/strawberry.png";
import passionFruit from "../../assets/images/passionFruit.png";
import lichee from "../../assets/images/litchi.png";
import Arbutus from "../../assets/images/Arbutus.png";
import Grapefruit from "../../assets/images/Grapefruit.png";
import coconutWine from "../../assets/images/coconut_txt.svg";
import lemonWine from "../../assets/images/lemon_txt.svg";
import peachWine from "../../assets/images/peach-txt.svg";
import StrawberryWine from "../../assets/images/strawberry_txt.svg";
import passionFruitWine from "../../assets/images/passionFruit_txt.svg";
import GrapefruitWine from "../../assets/images/Grapefruit_txt.svg";
import licheeWine from "../../assets/images/licheeWine.svg";
// import ArbutusWine from "../../assets/images/ArbutusWine.svg";
// import ArbutusWine from "../../assets/images/redbayberry-txt.svg";
import ArbutusWine from "../../assets/images/payberry-txt.svg";
import {Swiper} from "antd-mobile";

const QinBeer = () => {
    // let isIOS: boolean = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    // useEffect(() => {
    //     isIOS
    //     // const isAndroid = /Android/i.test(navigator.userAgent);
    //     // // 可以根据isIOS和isAndroid的值进行不同的操作
    // }, []);

    const [CraftBrewingList] = useState([
        {
            contentImg: peach,
            wine: peachWine,
            background: '#E7A9BC'
        },
        {
            contentImg: coconut,
            wine: coconutWine,
            background: '#F8A03F'
        },
        {
            contentImg: lemon,
            wine: lemonWine,
            background: '#E7A9BC'
        },
        {
            contentImg: Strawberry,
            wine: StrawberryWine,
            background: '#F8A03F'
        },
        {
            contentImg: passionFruit,
            wine: passionFruitWine,
            background: '#E7A9BC'
        },
        {
            contentImg: Grapefruit,
            wine: GrapefruitWine,
            background: '#F8A03F'
        },
        {
            contentImg: lichee,
            wine: licheeWine,
            background: '#E7A9BC'
        },
        {
            contentImg: Arbutus,
            wine: ArbutusWine,
            background: '#F8A03F'
        },
    ])


    return (
        <div id={'QinBeer'} className={'qinBeer  w-full h-auto'}>
            <div className={'title mx-auto'}>
                <img src={img1} alt=""/>
            </div>

            <div className={'mx-auto CraftBrewingTitles flex items-center justify-around'}>
                <div className={'lines w-48 h-1.5 rounded-md'}></div>
                <div className={'qinSerise'}>
                    <img src={qinSerise} alt=""/>
                </div>
                <div className={'lines w-48 h-1.5 rounded-md'}></div>
            </div>

            <div className={'CarouselMP mt-12'}>
                <Swiper indicator={() => null}>
                    {
                        CraftBrewingList.map((item, index) => {
                            return (
                                // style={{background: item.background}}
                                <Swiper.Item key={index}>
                                    <div key={index} className={'CraftBrewingCarousels mr-9'}>
                                        <div className={'h-full Carousels'}>
                                            <div key={index} className={'h-full flex items-center justify-evenly'}>
                                                <div className={'wine'}>
                                                    <img src={item.contentImg} alt=""/>

                                                </div>
                                                <div className={'contentImg'}>
                                                    <img src={item.wine} alt=""/>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Swiper.Item>


                            )

                        })
                    }
                </Swiper>
                {/*<Carousel dots={false}>*/}
                {/*    {*/}
                {/*        CraftBrewingList.map((item, index) => {*/}
                {/*            return (*/}
                {/*                // style={{background: item.background}}*/}
                {/*                <div key={index}  className={'CraftBrewingCarousels mr-9'}>*/}
                {/*                    <div  className={'h-full Carousels'}>*/}
                {/*                        <div key={index} className={'h-full flex items-center justify-evenly'}>*/}
                {/*                            <div className={'wine'}>*/}
                {/*                                <img src={item.contentImg} alt=""/>*/}

                {/*                            </div>*/}
                {/*                            <div className={'contentImg'}>*/}
                {/*                                <img src={item.wine} alt=""/>*/}
                {/*                            </div>*/}

                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*            )*/}

                {/*        })*/}
                {/*    }*/}
                {/*</Carousel>*/}
            </div>

            <div className={'CarouselWeb w-full flex items-center justify-center'}>
                <div style={{display: '-webkit-inline-box', width: '56.5rem'}}
                     className={' mx-auto mt-12  overflow-x-auto overflow-y-hidden'}>
                    {


                        CraftBrewingList.map((item, index) => {
                            return (

                                // style={{background: item.background}}
                                <div key={index} className={'CraftBrewingCarousels mr-9'}>
                                    <div className={'h-full Carousels'}>
                                        <div key={index} className={'h-full flex items-center justify-around'}>
                                            <div className={'wine'}>
                                                <img src={item.contentImg} alt=""/>

                                            </div>
                                            <div className={'contentImg'}>
                                                <img src={item.wine} alt=""/>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            )

                        })
                    }
                </div>
            </div>

            {/*<div className={'text-white flex items-center justify-center mt-3 '}>*/}
            {/*    <div className={'leftSlide text-right'}>左滑查看更多</div>*/}
            {/*    <div className={'w-6 h-6 ml-3'}>*/}
            {/*        <img src={arrows} className={'w-6 h-6'} alt=""/>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default QinBeer;