import React, {useState} from 'react';
import './index.scss'
import CraftBrewings from '../../assets/images/CraftBrewing.svg'
import BavarianPaleAle from '../../assets/images/BavarianPaleAle.svg'
import CelebrationBeer from '../../assets/images/CelebrationBeer.svg'
import ColaFlavoredWhiteBeer from '../../assets/images/ColaFlavoredWhiteBeer.svg'
import CraftLager from '../../assets/images/CraftLager.svg'
import CraftWheatBeer from '../../assets/images/CraftWheatBeer.svg'
import CraftStout from '../../assets/images/CraftStout.svg'
import CraftLagerWine from '../../assets/images/1.png'
import CraftWheatBeerWine from '../../assets/images/2.png'
import ColaFlavoredWhiteBeerWine from '../../assets/images/6.png'
import CelebrationBeerWine from '../../assets/images/4.png'
import CraftStoutWine from '../../assets/images/3.png'
import BavarianPaleAleWine from '../../assets/images/5.png'
import arrows from '../../assets/images/arrows.svg'
import {Swiper} from "antd-mobile";

const CraftBrewing = () => {
    // const onChange = () => {
    //
    // }

    // let isMp: boolean = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)


    // useEffect(() => {
    //     const container = document.querySelector(".CraftBrewingListMp");
    //     const items = document.querySelectorAll(".CraftBrewingCarousel");
    //     // @ts-ignore
    //     const containerWidth = container.offsetWidth;
    //     const itemWidth = containerWidth / items.length;
    //     const lastItem = document.querySelector(".CraftBrewingCarousel:last-child");
    //     console.log(items, 'lastItem')
    //
    //     setTimeout(() => {
    //         console.log(lastItem,'lastItem')
    //     })
    //     // @ts-ignore
    //     // lastItem.style.width = itemWidth + "px";
    //     // @ts-ignore
    //     lastItem.style.marginRight = "2.25rem";
    // }, []);

    const [CraftBrewingList] = useState([
        {
            contentImg: CraftLager,
            wine: CraftLagerWine,
        },
        {
            contentImg: CraftWheatBeer,
            wine: CraftWheatBeerWine,
        },
        {
            contentImg: CraftStout,
            wine: CraftStoutWine,
        },
        {
            contentImg: CelebrationBeer,
            wine: CelebrationBeerWine,
        },
        {
            contentImg: BavarianPaleAle,
            wine: BavarianPaleAleWine,
        },
        {
            contentImg: ColaFlavoredWhiteBeer,
            wine: ColaFlavoredWhiteBeerWine,
        },
    ])

    return (
        <div className={'CraftBrewingDom'}>
            <div id={'CraftBrewing'} className={'mt-4 CraftBrewing'}>
                <div className={'CraftBrewingTitle mx-auto'}>
                    <div className={' flex items-center justify-around'}>
                        <div className={'line w-48 h-1.5 rounded-md'}></div>
                        <div className={'CraftBrewings'}>
                            <img src={CraftBrewings} alt=""/>
                        </div>
                        <div className={'line w-48 h-1.5 rounded-md'}></div>
                    </div>


                </div>


            </div>

            <div className={'CarouselMP mt-12'}>
                <Swiper indicator={() => null}>
                    {
                        CraftBrewingList.map((item, index) => {
                            return (
                                // style={{background: item.background}}
                                <Swiper.Item key={index}>
                                    <div key={index} className={'CraftBrewingCarousel '}>
                                        <div className={'h-full Carousels'}>
                                            <div key={index} className={'h-full flex items-center justify-evenly'}>
                                                <div className={'wine'}>
                                                    <img src={item.wine} alt=""/>
                                                </div>
                                                <div className={'contentImg'}>
                                                    <img className={'w-full h-full'} src={item.contentImg} alt=""/>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Swiper.Item>


                            )

                        })
                    }
                </Swiper>
                {/*<Carousel dots={false}>*/}
                {/*    {*/}
                {/*        CraftBrewingList.map((item, index) => {*/}
                {/*            return (*/}
                {/*                <div key={index} className={'CraftBrewingCarousel '}>*/}
                {/*                    <div className={'h-full Carousels'}>*/}
                {/*                        <div key={index} className={'h-full flex items-center justify-evenly'}>*/}
                {/*                            <div className={'wine'}>*/}
                {/*                                <img src={item.wine} alt=""/>*/}
                {/*                            </div>*/}
                {/*                            <div className={'contentImg'}>*/}
                {/*                                <img className={'w-full h-full'} src={item.contentImg} alt=""/>*/}
                {/*                            </div>*/}

                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*            )*/}

                {/*        })*/}
                {/*    }*/}
                {/*</Carousel>*/}
            </div>

            <div className={'CarouselWeb w-full flex items-center justify-center'}>
                <div style={{display: '-webkit-inline-box', width: '56.5rem'}}
                     className={'CraftBrewingListMp mx-auto mt-12  overflow-x-auto overflow-y-hidden'}>

                    {
                        CraftBrewingList.map((item, index) => {
                            return (
                                <div key={index} className={'CraftBrewingCarousel mr-9'}>
                                    <div className={'h-full Carousels'}>
                                        <div key={index} className={'h-full flex items-center justify-evenly'}>
                                            <div className={'wine'}>
                                                <img src={item.wine} alt=""/>
                                            </div>
                                            <div className={'contentImg'}>
                                                <img className={'w-full h-full'} src={item.contentImg} alt=""/>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            )

                        })
                    }
                    {/*<div style={{width: '330px', paddingRight: '48px'}} className={'CraftBrewingCarousel mr-9'}>*/}
                    {/*    <div className={'h-full Carousels'}>*/}
                    {/*        <div  className={'h-full flex items-center justify-between'}>*/}
                    {/*            <div className={'wine'}>*/}
                    {/*                <img src={ColaFlavoredWhiteBeerWine} alt=""/>*/}
                    {/*            </div>*/}
                    {/*            <div className={'contentImg'}>*/}
                    {/*                <img className={'w-full h-full'} src={ColaFlavoredWhiteBeer} alt=""/>*/}
                    {/*            </div>*/}

                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>

            <div className={'CraftBrewing text-white flex items-center justify-center mt-3 pb-24'}>
                <div className={'leftSlide text-right'}>左滑查看更多</div>
                <div className={'w-6 h-6 ml-3'}>
                    <img src={arrows} className={'w-6 h-6'} alt=""/>
                </div>
            </div>
        </div>
    )
};

export default CraftBrewing;