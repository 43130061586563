import React from 'react';
import './App.css';

import Header from "./pages/Header";
import BrandIntroduction from "./pages/BrandIntroduction";
import CraftBrewing from "./pages/CraftBrewing";
import QinBeer from "./pages/QinBeer";
import Contact from "./pages/Contact";

function App() {
    return (
        <div className={'w-full h-full  background '}>
            <Header></Header>
            <BrandIntroduction></BrandIntroduction>
            <CraftBrewing></CraftBrewing>
            <QinBeer></QinBeer>
            <Contact></Contact>
        </div>
    );
}

export default App;
