import React, {useEffect, useState} from 'react';
import {Carousel} from 'antd';
import './index.scss'
import 'animate.css'
import in0 from '../../assets/images/banner1.png'
import in1 from '../../assets/images/banner2.png'
import in2 from '../../assets/images/banner3.png'
import in3 from '../../assets/images/banner4.png'
import mbTab1 from '../../assets/images/mbTab1.svg'
import mbTab2 from '../../assets/images/mbTab2.svg'
import brandIntroduction from "../../assets/images/brandIntroduction.svg";
import brandIntroductionEn from "../../assets/images/brandIntroductionEn.svg";
import history from '../../assets/images/history.svg'
import freshness from '../../assets/images/freshness.svg'
import hourglass from '../../assets/images/hourglass.svg'
import m1 from '../../assets/images/1m.svg'
import m2 from '../../assets/images/2m.svg'
import m3 from '../../assets/images/3m.svg'
import wheatWord from '../../assets/images/22.svg'
import wheat from '../../assets/images/wheat.svg'
import quality from '../../assets/images/quality.svg'
import qualityWord from '../../assets/images/qualityWord.svg'
import historyWord from '../../assets/images/historyWord.png'
import medalMp from '../../assets/images/medalMp.png'
import bg1 from '../../assets/images/bg1.png'
import bgFresh from '../../assets/images/bg-fresh.png'
import bgQuality from '../../assets/images/bg-quality.png'
import icon from '../../assets/images/icon.png'
import iconFresh from '../../assets/images/icon-fresh.png'
import iconQuality from '../../assets/images/icon-quality.png'
import tag from '../../assets/images/tag.png'
import tagFresh from '../../assets/images/tag-fresh.png'
import tagQuality from '../../assets/images/tag-quality.png'
import mub from '../../assets/images/mub.png'
import number2 from '../../assets/images/number2.png'
import number3 from '../../assets/images/numb3.png'


const BrandIntroduction = () => {

    interface Interface {
        name: string,
        isDot: boolean
    }

    const isContain = (dom: any) => {
        const viewHeight = window.innerHeight || document.documentElement.clientHeight;
        const viewWidth = window.innerWidth || document.documentElement.clientWidth;
        // 当滚动条滚动时，top, left, bottom, right时刻会发生改变。
        const {
            top,
            right,
            bottom,
            left
        } = dom.getBoundingClientRect();
        return (top >= 0 && left >= 0 && right <= viewWidth && bottom <= viewHeight);
    }


    useEffect(() => {

        // @ts-ignore
        let step1 = document.getElementById('step1')
        // @ts-ignore
        let step2 = document.getElementById('step2')
        let step3 = document.getElementById('step3')

        window.addEventListener('scroll', () => {

            let a = isContain(step1)
            let b = isContain(step2)
            let c = isContain(step3)

            console.log(a, b, c)

            // @ts-ignore

            // @ts-ignore
            if (a) {
                // @ts-ignore
                let stepHistorys = document.querySelector('.stepHistory')
                let stepHistoryMp = document.querySelector('.stepHistoryMp')
                let iconMp = document.querySelector('.iconMp')
                let stepHistorySon = document.querySelector('.stepHistorySon')
                // let mub = document.querySelector('.mub')
                let tagMp = document.querySelector('.tagMp')
                let stepOne = document.querySelector('.stepOne')
                // @ts-ignore

                stepHistorys.classList.add('animate__animated', 'animate__slideInLeft');
                // @ts-ignore
                stepHistoryMp.classList.add('animate__animated', 'animate__slideInLeft');
                // @ts-ignore
                // mub.classList.add('animate__animated', 'animate__slideInLeft');

                // @ts-ignore
                iconMp.classList.add('animate__animated', 'animate__slideInLeft');
                // @ts-ignore
                stepHistorySon.classList.add('animate__animated', 'animate__slideInLeft');
                // @ts-ignore
                tagMp.classList.add('animate__animated', 'animate__slideInLeft');
                // @ts-ignore
                stepOne.classList.add('animate__animated', 'animate__slideInRight');
            }
            if (b) {
                // @ts-ignore
                let stepWheat = document.querySelector('.stepWheat')
                let stepWheatSon = document.querySelector('.stepWheatSon')
                let stepTwo = document.querySelector('.stepTwo')
                let stepFreshMp = document.querySelector('.stepFreshMp')
                let iconFreshMp = document.querySelector('.iconFreshMp')
                let tagFresh = document.querySelector('.tagFresh')
                // let stepHistorySon = document.querySelector('.stepHistorySon')
                // @ts-ignore
                stepWheat.classList.add('animate__animated', 'animate__slideInRight');

                // @ts-ignore
                stepFreshMp.classList.add('animate__animated', 'animate__slideInRight');

                // @ts-ignore
                iconFreshMp.classList.add('animate__animated', 'animate__slideInRight');

                // @ts-ignore
                tagFresh.classList.add('animate__animated', 'animate__slideInRight');
                // @ts-ignore
                stepWheatSon.classList.add('animate__animated', 'animate__slideInRight');
                // @ts-ignore
                stepTwo.classList.add('animate__animated', 'animate__slideInLeft');
            }

            if (c) {
                // @ts-ignore
                let stepQuality = document.querySelector('.stepQuality')
                let stepQualityMp = document.querySelector('.stepQualityMp')
                let iconQualityMP = document.querySelector('.iconQualityMP')
                let tagQualityMp = document.querySelector('.tagQualityMp')
                let stepQualitySon = document.querySelector('.stepQualitySon')
                let stepThree = document.querySelector('.stepThree')
                // @ts-ignore
                stepQuality.classList.add('animate__animated', 'animate__slideInLeft');

                // @ts-ignore
                stepQualityMp.classList.add('animate__animated', 'animate__slideInLeft');

                // @ts-ignore
                iconQualityMP.classList.add('animate__animated', 'animate__slideInLeft');

                // @ts-ignore
                tagQualityMp.classList.add('animate__animated', 'animate__slideInLeft');
                // @ts-ignore
                stepQualitySon.classList.add('animate__animated', 'animate__slideInLeft');
                // @ts-ignore
                stepThree.classList.add('animate__animated', 'animate__slideInRight');
            }
            // // @ts-ignore
        })
    }, [])

    // @ts-ignore
    // const calc = (dom, distance) => {
    //     distance = distance || 0
    //     if (['BODY', null].includes(dom.offsetParent.nodeName)) {
    //         distance += dom.offsetTop
    //         return distance
    //     } else {
    //         distance += dom.offsetTop
    //         return calc(dom.offsetParent, distance)
    //     }
    // }

    // const HandleScroll = (e: any) => {
    //     console.log(e.target.scrollTop)
    // }

    const onChange = (currentSlide: number) => {
        console.log(currentSlide);
    };

    const [mbTabState, setMbTabState] = useState(true)

    const [nameList] = useState<Interface[]>([
        {
            name: 'golden-GID.svg',
            isDot: false
        },
        {
            name: 'drop-shadow.svg',
            isDot: true
        },
        {
            name: 'BeerStar.svg',
            isDot: false
        },
        {
            name: 'drop-shadow.svg',
            isDot: true,
        },
        {
            name: 'DLG.svg',
            isDot: false

        },
        {
            name: 'drop-shadow.svg',
            isDot: true
        },
        {
            name: 'LBC.svg',
            isDot: false
        },
    ])

    interface HeaderList {
        name: String;
        id: Number;
        isActive: Boolean;
    }

    const [headerList, setHeaderList] = useState<HeaderList[]>([
        {
            name: '品牌介绍',
            id: 0,
            isActive: true
        },
        {
            name: '精酿系列',
            id: 1,
            isActive: false
        },
        {
            name: '果味沁啤',
            id: 2,
            isActive: false
        },
        {
            name: '联系我们',
            id: 3,
            isActive: false
        }
    ])

    const changeMbTab = () => {
        setMbTabState(!mbTabState)
    }

    const clickNavs = (item: HeaderList) => {
        setHeaderList(
            headerList.map((i: HeaderList) => {
                if (i.id === item.id) {
                    return {...i, isActive: true}
                } else {
                    return {...i, isActive: false}
                }
            })
        )

        // @ts-ignore
        switch (item.id) {
            case 0:
                console.log(item);
                // @ts-ignore
                document.body.scrollTop = document.documentElement.scrollTop = 0
                // document.getElementById('brandIntroduction').scrollIntoView()
                break;
            case 1:
                // @ts-ignore
                let dom = document.getElementById('CraftBrewing')
                // @ts-ignore
                dom.scrollIntoView()
                console.log(dom)
                break;

            case 2:
                // @ts-ignore
                let dom1 = document.getElementById('QinBeer')
                // @ts-ignore
                dom1.scrollIntoView()
                break;

            case 3:
                // @ts-ignore
                let dom2 = document.getElementById('Contact2')
                // @ts-ignore
                dom2.scrollIntoView()
                break;
            default:
                console.log(item)
        }
        changeMbTab()
    }

    return (
        <>
            <div id={'brandIntroduction'} className={'Carousel w-56 '}>
                <div className={'relative'}>
                    <Carousel dots={{className: 'dotsClass'}} afterChange={onChange}>
                        <div>
                            <img key={0} src={in0} alt=""/>
                        </div>
                        <div>
                            <img key={1} src={in1} alt=""/>
                        </div>
                        <div>
                            <img key={2} src={in2} alt=""/>
                        </div>
                        <div>
                            <img key={3} src={in3} alt=""/>
                        </div>

                    </Carousel>

                    <div className={'mbTab'}>
                        <div style={{height: '40px'}} className={'h-full w-full'} onClick={() => changeMbTab()}>
                            <img style={{display: mbTabState ? 'block' : 'none', float: 'right'}} src={mbTab1} alt=""/>
                            <img style={{display: !mbTabState ? 'block' : 'none', float: 'right'}} src={mbTab2} alt=""/>
                        </div>

                        <div style={{display: !mbTabState ? 'block' : 'none'}} className={'mbList'}>
                            {
                                headerList.map((item, index) => {
                                    return (
                                        <div onClick={() => {
                                            clickNavs(item)
                                        }} className={item.isActive ? 'ac mbTabs' : 'mbTabs'}
                                             key={index}>{item.name}</div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className={'brandIntroductionLogo pb-28'}>
                    <div className={'brandIntroduction'}>
                        <img src={brandIntroduction} alt="品牌介绍"/>
                    </div>
                    <div className={'brandIntroductionLine'}></div>

                    <div className={'brandIntroductionEn'} style={{marginTop: '0.4375rem'}}>
                        <img src={brandIntroductionEn} alt="品牌介绍英文"/>
                    </div>

                    <div className={'introduction'}>
                        <p className={'text-justify'}>1688年，在德国巴伐利亚州名为Brand（音译“巴兰德”）小镇的古堡里，巴兰德真味开始了初次酿造，超过300多年的品牌历史始于巴伐利亚东北部这个热爱啤酒的地方。
                            <br/>
                            穿梭历史的千变万化，一份对佳酿孜孜以求的恒心始终如一，在无数啤酒爱好者的喜爱和啤酒专家的认可中愈发坚韧、历久弥新。
                            <br/>
                            德语“Urstoff”意涵“真味”，我们重视它在品牌名称中的份量，并用真切实践不遗余力地成就巴兰德真味（Brander
                            Urstoff）品牌为精酿啤酒重要代表之一。
                            <br/>
                            在中国，我们致力于焕新德国啤酒的刻板印象，为大家带来新鲜、时髦并融入中式元素的德国啤酒。
                        </p>
                    </div>
                </div>


            </div>

            <div id={'step1'} className={'w-full flex items-center'}>
                <div className={'stepWeb w-full flex items-center'}>
                    <div className={'stepHistory fadeInLeft animate__animated '}>
                        <div className={'fadeInLeftSon stepHistorySon flex items-center mt-3.5 '}>
                            <div className={'flex items-center fadeInLeftSonDom'}>
                                <div className={'hourglass'}>
                                    <img className={'w-full h-full'} src={hourglass} alt="沙漏图标"/>
                                </div>

                                <div className={'historyWord h-10 mx-4 '}>
                                    <img className={'w-full h-full'} src={historyWord} alt="历史"/>
                                </div>
                            </div>

                            <div className={'historyText'}>
                                <div className={'flex '}>
                                    <div
                                        className={'GermanBrand w-32 h-10  flex items-center justify-center'}>德国品牌
                                    </div>
                                    <div
                                        className={'GermanBrand fromYear flex items-center justify-center h-10 ml-2'}>酿造传统源于1688
                                    </div>
                                </div>
                                <div
                                    className={'GermanBrand historyYear h-10 flex items-center justify-center mt-2'}>超过300年品牌历史
                                </div>
                            </div>

                            <div className={'stepOneM'}>
                                <div>
                                    <img src={m1} alt=""/>
                                </div>
                            </div>
                        </div>

                        <div className={'fadeInLeftSon animate__animated animate__slideInLeft mt-4'}>
                            <p>巴兰德真味是一个拥有超过300年酿造历史的德国啤酒品牌。在巴兰德真味，我们将德国的酿造技术与进口原料相结合，创造出精酿啤酒，并以其新鲜度和卓越的口感闻名。</p>
                        </div>
                    </div>

                    <div className={'stepOne   ml-2 flex items-center justify-center'}>
                        <div className={'w-full flex items-center justify-center history'}>
                            <img src={history} alt=""/>
                        </div>
                    </div>
                </div>
                <div className={'stepMp stepHistoryMp relative'}>=
                    <div className={''} style={{width: '98%',}}>
                        <img src={bg1} alt=""/>
                    </div>
                    <div className={'absolute iconMp'}
                         style={{width: '9rem', top: '2.5625rem', left: '1.8125rem', animationDelay: '0.5s'}}>
                        <img src={icon} alt=""/>
                    </div>

                    <div className={'absolute tagMp'}
                         style={{width: '13rem', top: '7.5625rem', left: '1.8125rem', animationDelay: '1s'}}>
                        <img src={tag} alt=""/>
                    </div>

                    <div className={'absolute mub'}
                         style={{width: '8.2rem', top: '1.5625rem', right: '0.8125rem', animationDelay: '1.3s'}}>
                        <img src={mub} alt=""/>
                    </div>
                </div>
            </div>

            <div id={'step2'} className={'flex items-center flex-row-reverse mt-16'}>
                <div className={'stepWeb w-full flex flex-row-reverse items-center'}>
                    <div className={'fadeInRight stepWheat'}>
                        <div className={'fadeInLeftSon stepWheatSon flex items-center mt-3.5 '}>
                            <div className={'flex items-center fadeInLeftSonDom'}>
                                <div className={'hourglass'}>
                                    <img className={'w-full h-full'} src={wheat} alt="麦子图标"/>
                                </div>

                                <div className={'historyWord h-10 mx-4 '}>
                                    <img className={'w-full h-full'} src={wheatWord} alt="历史"/>
                                </div>
                            </div>

                            <div className={'historyText'}>
                                <div className={'flex items-center'}>
                                    <div
                                        className={'GermanBrand w-40 h-10  flex items-center justify-center'}>德国纯度法
                                    </div>
                                    <div
                                        className={'GermanBrand w-32  flex items-center justify-center h-10 ml-2'}>德国技术
                                    </div>
                                </div>
                                <div className={'flex items-center mt-2'}>
                                    <div
                                        className={'GermanBrand w-52 h-10  flex items-center justify-center'}>进口优质原材料
                                    </div>
                                    <div
                                        className={'GermanBrand w-56  flex items-center justify-center h-10 ml-2'}>德国啤酒酿造大师
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className={'stepTwoM'}>
                            <div>
                                <img src={m2} alt=""/>
                            </div>
                        </div>

                        <div className={'fadeInLeftSon  mt-4'}>
                            <p>巴兰德真味引进德国酿造技术，用优质的进口原材料和纯净的中国水源，在中国本地新鲜酿造，并因其鲜爽醇真受到大众喜爱。
                                巴兰德真味精酿啤酒的酿造工艺严格遵循德国啤酒纯度法，由德国酿酒大师全程现场严格监管。我们的精酿啤酒符合国际高品质标准，以高品质和出色口感获得德国农业协会（DLG）和欧洲啤酒之星荣誉。</p>
                        </div>
                    </div>

                    <div className={'stepTwo  ml-2 flex items-center justify-center'}>
                        <div className={'w-full flex items-center justify-center history'}>
                            <img src={freshness} alt=""/>
                        </div>
                    </div>
                </div>

                <div className={'stepMp relative stepFreshMp'}>
                    <div className={''} style={{width: '98%', float: 'right'}}>
                        <img src={bgFresh} alt=""/>
                    </div>

                    <div className={'absolute iconFreshMp'}
                         style={{width: '9rem', top: '1.5625rem', left: '7.8125rem', animationDelay: '0.5s'}}>
                        <img src={iconFresh} alt=""/>
                    </div>

                    <div className={'absolute tagFresh'}
                         style={{width: '13rem', top: '6.5625rem', left: '7.8125rem', animationDelay: '1s'}}>
                        <img src={tagFresh} alt=""/>
                    </div>

                    <div className={'absolute mub'}
                         style={{width: '8.2rem', top: '1.5625rem', left: '10px', animationDelay: '1.3s'}}>
                        {/*left: '0.8125rem',*/}
                        <img src={number2} alt=""/>
                    </div>
                </div>

            </div>

            <div id={'step3'} className={'w-full flex items-center mt-16 '}>

                <div className={'stepWeb w-full flex items-center'}>
                    <div className={'fadeInLeft stepQuality '}>
                        <div className={'fadeInLeftSon stepQualitySon flex items-center mt-3.5 '}>
                            <div className={'flex items-center fadeInLeftSonDom'}>
                                <div className={'hourglass'}>
                                    <img className={'w-full h-full'} src={quality} alt="品质图标"/>
                                </div>

                                <div className={'historyWord h-10 mx-4 '}>
                                    <img className={'w-full h-full'} src={qualityWord} alt="历史"/>
                                </div>
                            </div>

                            <div className={'historyText'} style={{height: "auto"}}>
                                <div className={'flex items-center'}>
                                    <div
                                        className={'GermanBrand w-40 h-9  flex items-center justify-center'}>中国本地酿造
                                    </div>
                                    <div
                                        className={'GermanBrand w-28  flex items-center justify-center h-9 ml-2'}>优质水源
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={'stepThreeM'}>
                            <div>
                                <img src={m3} alt=""/>
                            </div>
                        </div>
                        <div className={'fadeInLeftSon animate__animated animate__slideInLeft mt-2'}>
                            <p>我们的德国酿酒大师以严苛的质量控制标准把关本地酿造水源的新鲜和进口原材料的质量。
                                供应巴兰德真味的门店数目每个月都在迅速增长。目前巴兰德真味累计有上千个售点，全面覆盖酒吧、餐厅、便利店、超市、KTV、电商等渠道。</p>
                        </div>


                    </div>

                    <div className={'stepThree  ml-2 flex items-center justify-center'}>
                        <div className={'w-full flex items-center justify-center history'}>
                            <img src={history} alt=""/>
                        </div>
                    </div>
                </div>

                <div className={'stepMp relative stepQualityMp'}>
                    <div className={''} style={{width: '98%',}}>
                        <img src={bgQuality} alt=""/>
                    </div>
                    <div className={'absolute iconQualityMP'}
                         style={{width: '9rem', top: '2.5625rem', left: '1.8125rem', animationDelay: '0.5s'}}>
                        <img src={iconQuality} alt=""/>
                    </div>

                    <div className={'absolute tagQualityMp'}
                         style={{width: '13rem', top: '7.5625rem', left: '1.8125rem', animationDelay: '1s'}}>
                        <img src={tagQuality} alt=""/>
                    </div>

                    <div className={'absolute mub'}
                         style={{width: '8.2rem', top: '1.5625rem', right: '0.8125rem', animationDelay: '1.3s'}}>
                        <img src={number3} alt=""/>
                    </div>
                </div>

            </div>

            <div className={'mt-16 medal mx-auto flex items-center justify-around'}>
                {
                    nameList.map((item, index) => {
                        return (
                            <div className={''} key={index}>
                                <img object-fit={'contain'} className={item.isDot ? 'dWidth' : 'bWidth mWidth'}
                                     src={require(`../../assets/images/${item.name}`)} alt={''}></img>
                            </div>
                        )
                    })
                }
            </div>

            <div className={'medalMp'}>
                <img src={medalMp} alt=''/>
            </div>

        </>
    );
};

export default BrandIntroduction;
