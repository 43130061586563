import React from 'react';
import './index.scss'
import qinLogo from '../../assets/images/qinLogo.svg'
import plus from '../../assets/images/plus.svg'
import eq from '../../assets/images/eq.svg'
import qrcode from '../../assets/images/qrcode.svg'
// import tmall from '../../assets/images/tmall.svg'
// import jd from '../../assets/images/jd.svg'
import tmall from '../../assets/images/tianmao.png'
// import jd from '../../assets/images/jingdong.png'
import jd from '../../assets/images/jingdong.svg'
import wechat from '../../assets/images/wechat.svg'
import tictok from '../../assets/images/tictok.svg'
import redBook from '../../assets/images/redBook.svg'
import search from '../../assets/images/search.svg'
import companyCh from '../../assets/images/companyCh.svg'
import companyEn from '../../assets/images/companyEn.svg'
import videoAcc from '../../assets/images/videoAcc.png'
import weibo from '../../assets/images/weibo.png'
import Slice13 from '../../assets/images/Slice13.png'
import Slice14 from '../../assets/images/Slice141.png'
import Slice15 from '../../assets/images/Slice15.png'
import Slice16 from '../../assets/images/Slice16.png'
import Slice17 from '../../assets/images/Slice17.png'
import {Divider} from "antd";

const Contact = () => {
    const contactUs = (web: string) => {
        console.log(web)

        if (web === 'tm') {
            const w = window.open('_black')
            // @ts-ignore
            w.location.href = 'https://branderurstoff.tmall.com/'
        } else {
            const w = window.open('_black')
            // @ts-ignore
            w.location.href = 'https://mall.jd.com/index-622936.html'
        }
    }

    return (
        <div className={'Contact w-full h-auto min-h-screen'}>
            <div className={'webs'}>
                {/*<div>*/}
                {/*    <img className={'mx-auto qinLogo'} src={qinLogo} alt=""/>*/}
                {/*</div>*/}

                {/*<div className={'mt-14'}>*/}
                {/*    <p className={'mps leading-loose w-1/2 mx-auto text-white text-base'}>*/}
                {/*        传统而创新是我们的DNA，激发我们传承啤酒精神时，更无数次探索啤酒的可塑性。<br/>*/}
                {/*        继精酿啤酒系列之后，开启啤酒想象力的  <span >「沁啤」</span> 系列诞生，得名于口味测试中的高频形容 <span >“沁人心啤 ”</span>。它由悉心*/}
                {/*        遴选的水果味赋予精酿拉格妙趣，带来8款沁心的水果味低醇啤酒。*/}
                {/*    </p>*/}
                {/*</div>*/}

                {/*<div className={'vector mx-auto mt-16 flex items-center justify-center'}>*/}
                {/*    <div>*/}
                {/*        <img src={vector} alt=""/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <img src={Slice13} className={'w-full'} alt=""/>
            </div>

            <div className={'websBac'}></div>

            {/*<div className={'webs fansSay mt-16 mx-auto'}>*/}
            {/*    <img src={Slice14} alt=""/>*/}
            {/*    <div className={'fansSayTit mx-auto'}>粉丝们欢喜，给了它很多称号：</div>*/}

            {/*    <div className={'mt-8'}>*/}
            {/*        <div className={'hospitality fansSayTxt text-center underline'}>款待自己的酒</div>*/}
            {/*    </div>*/}

            {/*    <div className={'w-full justify-around flex items-center mt-4'}>*/}
            {/*        <div className={'hospitality fansSayTxt text-center underline'}>好喝的酒</div>*/}
            {/*        <div className={'hospitality fansSayTxt text-center underline'}>治愈的酒</div>*/}
            {/*    </div>*/}

            {/*    <div className={'mt-4'}>*/}
            {/*        <div className={'hospitality fansSayTxt text-center underline'}>想喝就喝的酒</div>*/}
            {/*    </div>*/}

            {/*    <div className={'w-full justify-around flex items-center mt-4'}>*/}
            {/*        <div className={'hospitality fansSayTxt text-center underline'}>有趣的酒</div>*/}
            {/*        <div className={'hospitality fansSayTxt text-center underline'}>友好的酒</div>*/}
            {/*    </div>*/}

            {/*    <div className={'mt-4'}>*/}
            {/*        <div className={'hospitality fansSayTxt text-center underline'}>还没喝心情就好的酒</div>*/}
            {/*    </div>*/}

            {/*    <div className={'mt-4'}>*/}
            {/*        <div className={'hospitality fansSayTxt text-center underline'}>开心的酒</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={'webs footerMp'}>
                <img src={Slice14} className={'w-full'} alt=""/>

            </div>

            <div className={'websBac2'}></div>


            <div className={'footerWeb'}>
                <div className={'flex items-center justify-center mt-10 mPP'}>
                    <div className={'redR rounded-full flex items-center justify-center'}>
                        <div className={'formula formulaF flex items-center justify-cente'}>沁心<br/>果味</div>
                    </div>

                    <div className={'plus w-11 h-11 mx-8'}>
                        <img src={plus} alt=""/>
                    </div>

                    <div className={'redR rounded-full flex items-center justify-center'}>
                        <div className={'formula formulaF'}>精酿<br/>啤酒</div>
                    </div>

                    <div className={'plus flex items-cente w-11 h-11 mx-8'}>
                        <img src={eq} alt=""/>
                    </div>

                    <div className={'redR rounded-full flex items-center justify-center'}>
                        <div className={'formula formulaT'}>沁啤</div>
                    </div>
                </div>

                <div className={'flex items-center justify-center'}>
                    <img className={'mt-80 mQinLogo'} src={qinLogo} alt=""/>
                </div>

                <div id={'Contact'} className={'flex items-center justify-center mt-16'}>
                    <p className={'lastWord'} style={{marginLeft: '9%'}}>
                        <span className={'lasts'}>让选择多一点，口感好一点，美好久一点。</span>
                        不管你是啤酒爱好者、入门小白还是果味饕客，<br/>
                        何时何地，都可以感受这份<span className={'lasts'}>水果味的微微醺</span>。
                        毕竟生活百味，<span className={'lasts'}>「沁人心啤」</span> 才对味。</p>
                </div>


                <div className={'qrcode flex items-center justify-center'}>
                    <img src={qrcode} alt=""/>
                </div>

                <div className={'mt-4 text-center'} style={{color: '#F8FAE4', fontSize: '1rem'}}>扫码添加客服联系我们
                </div>

                <div className={'MFJianHei mt-20 text-center buyOnLine'}>线上哪里购买</div>

                <div className={'flex items-center mt-8 justify-center h-auto'}>
                    <div onClick={() => {
                        contactUs('tm')
                    }} className={'mr-20 onlineLogo tm cursor-pointer'}>
                        <img className={'w-full h-full'} src={tmall} alt=""/>
                        <div className={'onlineLogoName  text-center mt-4'}>天猫</div>
                    </div>
                    <div onClick={() => {
                        contactUs('jd')
                    }} className={'onlineLogo cursor-pointer'}>
                        <img className={'w-full h-full'} src={jd} alt=""/>
                        <div className={'onlineLogoName text-center mt-4'}>京东</div>
                    </div>
                </div>

                <div className={'MFJianHei buyOnLine buyOnLineM mt-20 text-center'}>
                    寻找更多欢沁 收获更多惊喜
                </div>

                <div className={'flex items-center mt-11 justify-center mF'}>
                    <div className={'logoF mr-20 h-auto'}>
                        <img className={'onlineLogo'} src={wechat} alt=""/>
                        <div className={'onlineLogoName text-center mt-4'}>微信公众号</div>
                    </div>
                    <div className={'logoF mr-20 h-auto'}>
                        <img className={'onlineLogo'} src={videoAcc} alt=""/>
                        <div className={'onlineLogoName text-center mt-4'}>微信视频号</div>
                    </div>
                    <div className={'logoF mr-20 h-auto'}>
                        <img className={'onlineLogo'} src={tictok} alt=""/>
                        <div className={'onlineLogoName text-center mt-4'}>抖音</div>
                    </div>
                    <div className={'logoF mr-20 h-auto'}>
                        <img className={'onlineLogo'} src={redBook} alt=""/>
                        <div className={'onlineLogoName text-center mt-4'}>小红书</div>
                    </div>
                    <div className={'h-auto'}>
                        <img className={'onlineLogo'} src={weibo} alt=""/>
                        <div className={'onlineLogoName text-center mt-4'}>微博</div>
                    </div>
                </div>

                <div className={'searchUs flex items-center mx-auto'}>
                    <div className={'MFJianHei searchWord buyOnLine mr-6'} style={{width: '17%'}}>搜索</div>
                    <div className={'searchUsInput h-full rounded-full flex items-center justify-around'}>
                        <div className={'searchUsText'} style={{fontSize: '2rem', fontWeight: 500}}>巴兰德真味啤酒</div>
                        <div className={'searchIcon'}>
                            <img src={search} alt=""/>
                        </div>
                    </div>
                </div>

                <div style={{width: '40%'}} className={'attentionDom mx-auto text-center mt-28'}>
                    <p className={'MFJianHei attention'}>
                        理性享用美酒，未成年人请勿饮酒。
                        <br/>
                        酒后请勿驾驶，请勿分享此内容给未成年人。
                    </p>
                </div>

                <div className={'address h-44 mx-auto text-center'}>
                    {/*<div>上海德悦柏国际贸易有限公司</div>*/}
                    <div>
                        <img src={companyCh} alt=""/>
                    </div>
                    {/*<div >SHANGHAI&nbsp;BRANDER&nbsp;URSTOFF&nbsp;TRADING&nbsp;CO.,LTD.</div>*/}
                    <div className={'mt-3'}>
                        <img src={companyEn} alt=""/>
                    </div>
                    <div className={'addressTxt mt-6'}>
                        <div>
                            上海市静安区新疆路518号WeWork 5层128室，邮编200070
                        </div>
                        <div>
                            WeWork 5F, Room 128, 518 Xinjiang Road,
                        </div>
                        <div>
                            200070 Shanghai, China
                        </div>
                    </div>
                </div>

                <div style={{color: '#FDFAE0', textAlign: 'center', marginTop: '2rem'}}>
                    <div>
                        &copy;2023上海德悦柏国际贸易有限公司
                    </div>

                    <div>
                        &copy;2023 Shanghai Brander Urstoff Trading Co.,Ltd.
                    </div>
                </div>


                <div className={'footers h-6 flex items-center justify-around mx-auto'}>
                    <div className={'mbtxt1'}>版权所有: 上海德悦柏国际贸易有限公司</div>
                    <Divider style={{color: '#F8FAE4'}} className={'h-full'} type="vertical"/>
                    <div onClick={() => window.open('https://beian.mps.gov.cn/#/query/webSearch')} className='mbtxt2 cursor-pointer'> 备案号: 沪ICP备2023017117号-3</div>
                    <Divider style={{color: '#F8FAE4'}} className={'h-full'} type="vertical"/>
                    <div className={'mbtxt3'}>信息产业部备案管理系统</div>
                </div>
            </div>

            <div className={'footerMp'}>
                <div>
                    <img src={Slice15} className={'w-full'} alt=""/>
                </div>
            </div>
            <div id={'Contact2'} className={'footerMp'}>
                <div className={'relative'}>
                    <img src={Slice16} className={'w-full'} alt=""/>
                    <div className={'links w-full absolute flex items-center justify-center h-auto'}>
                        <div onClick={() => {
                            contactUs('tm')
                        }} className={'mr-6 onlineLogo tm cursor-pointer'}>
                            <img className={'w-full h-full'} src={tmall} alt=""/>
                            <div className={'onlineLogoName  text-center mt-4'}>天猫</div>
                        </div>
                        <div onClick={() => {
                            contactUs('jd')
                        }} className={'onlineLogo cursor-pointer'}>
                            <img className={'w-full h-full'} src={jd} alt=""/>
                            <div className={'onlineLogoName text-center mt-4'}>京东</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'footerMp'}>
                <div>
                    <img src={Slice17} className={'w-full'} alt=""/>
                </div>

            </div>


        </div>
    );
};

export default Contact;